import globalStore from '@/services/global.state';
import theme from '@/style';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {RefreshControl, ScrollView, StatusBar, View} from 'react-native';
import HomeHeader from './components/home-header';
import DownloadBanner from './components/home-download-banner';
import HomeBanner from './components/home-banner';
import HomeGoldArea from './components/home-gold-area';
import HomeFloorGames from './components/home-floor-games';
import HomeWinningInformation from './components/home-winning-information';
import HomeSide from './components/home-side';
import {getBetBy, getHomeGames, getNoticeCheck} from './home.service';
import {BasicObject} from '@types';
import {HomeGameFloorItem} from './home.type';
import {useFocusEffect} from '@react-navigation/native';
import {useInnerStyle} from './home.hooks';
import {FadeInView} from '@/components/basic/animations';
import {envConfig, goTo, useResponsiveDimensions} from '@/utils';
import HomeFloorLottery from './components/home-floor-lottery';

const Home = () => {
  const [homeGames, setHomeGames] = useState<HomeGameFloorItem[]>();
  const [casinoCategoryId, setCasinoCategoryId] = useState(5);
  const [liveCategoryId, setLiveCategoryId] = useState(6);
  const {spaceAreaStyles} = useInnerStyle();
  const [refreshing, setRefreshing] = useState(false);
  const [version, setVersion] = useState(0);
  const [noticeMap, setNoticeMap] = useState<BasicObject>({
    FREE_LOTTERY: 0,
    REBATE: 0,
    LUCKY_SPIN: 0,
  });
  const [login, setLogin] = useState(false);
  const [focused, setFocused] = useState(false);

  const doNotice = (token: string | null) => {
    if (token) {
      getNoticeCheck().then(noticeList => {
        const newNoticeMap: BasicObject = {};
        Object.keys(noticeMap).forEach(key => {
          const notice = noticeList.find(item => item.noticeKey === key);
          if (notice) {
            newNoticeMap[key] = notice.noticeCount;
          }
        });
        setNoticeMap(newNoticeMap);
      });
    } else {
      setNoticeMap({
        FREE_LOTTERY: 0,
        REBATE: 0,
        LUCKY_SPIN: 0,
      });
    }
  };
  React.useEffect(() => {
    StatusBar.setBackgroundColor(theme.basicColor.primary);
    StatusBar.setBarStyle('dark-content');
    init();
  }, []);
  const init = (loading: boolean = true) => {
    loading && globalStore.globalLoading.next(true);
    return getHomeGames()
      .then(games => {
        setHomeGames(games);
        const casinoList = games.find(
          g => g.uniqueKey === 'indianCasino',
        )?.gamesList;
        const liveList = games.find(
          g => g.uniqueKey === 'liveCasinoV2',
        )?.gamesList;
        setCasinoCategoryId(casinoList ? casinoList[0]?.categoryId || 5 : 5);
        setLiveCategoryId(liveList ? liveList[0]?.categoryId || 6 : 6);
      })
      .finally(() => globalStore.globalLoading.next(false));
  };
  const handleFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      doNotice(token);
      setLogin(!!token);
      if (token) {
        globalStore.amountCheckOut.next({});
      }
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useFocusEffect(handleFocusEffect);
  const handleRefresh = () => {
    setRefreshing(true);
    setVersion(_v => _v + 1);
    Promise.allSettled([init(false), doNotice(globalStore.token)]).finally(() =>
      setRefreshing(false),
    );
  };

  const debounceTimer = useRef<NodeJS.Timeout>();
  const widgetRef = useRef<any>();

  const handleInitBetbyWidget = () => {
    if (widgetRef.current != null) {
      return;
    }
    const promodiv = document.getElementById('betby-promo-sports');
    getBetBy().then(res => {
      widgetRef.current = new BTRenderer().initialize({
        // Brand id
        brand_id: res.brandId,
        // User token
        token: res.token,
        // Language
        lang: 'en',
        // Dom node for widget
        target: promodiv,
        // Optional theme override,
        // should not be set if you want to use your
        // default brand themes
        themeName: 'default',
        // Widget name:
        widgetName: 'promo',
        // Params specific to widget
        widgetParams: {
          // placeholder name from backoffice
          placeholder: 'operator_page1',
          // callback to change url when user clicks on banner
          onBannerClick: (args: any) => {
            console.log('banner click', args);
            if (!login) {
              goTo('Login');
            }
            goTo('BetBy', {
              'bt-path': args.url,
            });
          },

          // withSportsList: true,
          // callback to change url when user clicks on sport icon within sports menu
          onSportClick: (args: any) => {
            console.log('sports click', args);
            if (!login) {
              goTo('Login');
            }
            goTo('BetBy', {
              'bt-path': args.url,
            });
            // goTo('BetBy', {})
          },
        },
      });
    });
  };

  const handleInit = useCallback(() => {
    debounceTimer.current = setTimeout(() => {
      if (!document.getElementById('betbyScript')) {
        const el = document.createElement('script');
        el.src = envConfig.betbyScriptUrl;
        el.id = 'betbyScript';
        document.body.insertBefore(el, null);
        el.onload = handleInitBetbyWidget;
      } else {
        handleInitBetbyWidget();
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);

  useFocusEffect(
    useCallback(() => {
      setFocused(true);
    }, []),
  );

  useEffect(() => {
    if (focused) {
      handleInit();
    }

    return () => {
      const current = widgetRef.current;
      if (current != null) {
        current.kill();
        widgetRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, login]);
  const {width: screenWidth} = useResponsiveDimensions();
  return (
    <FadeInView
      style={[
        theme.fill.fill,
        {
          minWidth: screenWidth,
        },
        theme.flex.col,
      ]}>
      <HomeHeader />
      {globalStore.isWeb ? <DownloadBanner /> : null}
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }>
        <View style={{height: theme.paddingSize.l}} />
        <HomeBanner version={version} />
        <HomeGoldArea
          casinoCategoryId={casinoCategoryId}
          liveCategoryId={liveCategoryId}
        />

        <div id="betby-promo-sports" />

        {homeGames?.map((floor, index) => {
          if (
            floor.uniqueKey === 'lotteryV2' &&
            floor.gamesList[3].gameUrl === '*'
          ) {
            return (
              <HomeFloorLottery
                key={index}
                title={floor.name}
                icon={floor.categoryPic}
                list={floor.gamesList || []}
              />
            );
          }
          return floor.gamesList ? (
            <HomeFloorGames
              type={floor.uniqueKey}
              key={index}
              title={floor.name}
              icon={floor.categoryPic}
              list={floor.gamesList || []}
            />
          ) : null;
        })}

        <HomeWinningInformation version={version} />
        <View style={[theme.fill.fillW, spaceAreaStyles.bottom]} />
      </ScrollView>
      <HomeSide
        noticeMap={noticeMap}
        onNotice={() => doNotice(globalStore.token)}
      />
    </FadeInView>
  );
};
export default Home;
