import React, {useCallback, useEffect, useRef, useState} from 'react';
import {getBetBy} from '../home/home.service';
import {envConfig, getUrlParams, goBack, goTo} from '@/utils';

import {CommonActions, useNavigation} from '@react-navigation/native';
import {homePage} from '@/config';
import globalStore from '@/services/global.state';
import {useFocusEffect, useNavigationState} from '@react-navigation/native';

const BetByContainer = () => {
  const navigation = useNavigation();
  const state = useNavigationState(_state => _state);
  const [token, setToken] = useState('');

  const toggle = useCallback((show = false) => {
    const container = document.getElementById('betby-container');
    if (container) {
      container.style.display = show ? 'flex' : 'none';
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      const status = localStorage.getItem('betbyRouteStatus');
      if (status && state.routes.length <= 1) {
        try {
          const _status = JSON.parse(status);
          const _route = _status.routes.find((r: any) => r.name === 'BetBy');
          if (_route) {
            const params = getUrlParams();
            _route.params = params;
            _status.stale = false;
            navigation.dispatch(CommonActions.reset(_status));
            // navigationRef.dispatch(CommonActions.reset(_status));
          } else {
            localStorage.removeItem('betbyRouteStatus');
          }
        } catch (e) {
          localStorage.removeItem('betbyRouteStatus');
        }
      } else {
        if (state) {
          localStorage.setItem('betbyRouteStatus', JSON.stringify(state));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  const handleInitBetby = useCallback(() => {
    getBetBy().then(res => {
      const {brandId, token} = res;
      (window as any).platformBetby = new BTRenderer().initialize({
        brand_id: brandId,
        token: token,
        onTokenExpired() {
          // goTo('Login', {backPage: homePage});
          return new Promise(resolved => {
            getBetBy().then(info => resolved(info.token));
          });
          // goTo(homePage);
          // localStorage.removeItem('betbyRouteStatus');
        },
        themeName: 'default',
        lang: globalStore.lang ? globalStore.lang.split('_')[0] : 'en',
        target: document.getElementById('betby'),
        betSlipOffsetTop: 52,
        betSlipOffsetBottom: 80,
        stickyTop: 0,
        betslipZIndex: 999,
        onSessionRefresh() {
          goTo(homePage, {screen: 'Home'});
          localStorage.removeItem('betbyRouteStatus');
        },
        onLogin() {
          goTo('Login', {backPage: homePage, sucessPage: homePage});
          localStorage.removeItem('betbyRouteStatus');
        },
        onRegister() {
          goTo('Login', {backPage: homePage, sucessPage: homePage});
          localStorage.removeItem('betbyRouteStatus');
        },
        onRecharge() {
          goTo('Recharge');
          localStorage.removeItem('betbyRouteStatus');
        },
      });
    });
  }, []);

  const debounceTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(_token => {
      if (!_token) {
        goTo('Login', {backPage: homePage, sucessPage: homePage});
        localStorage.removeItem('betbyRouteStatus');
      } else {
        setToken(_token);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!(window as any).platformBetby) {
      debounceTimer.current = setTimeout(() => {
        if (!document.getElementById('betbyScript')) {
          const el = document.createElement('script');
          el.src = envConfig.betbyScriptUrl;
          el.id = 'betbyScript';
          document.body.insertBefore(el, null);
          el.onload = handleInitBetby;
        } else {
          handleInitBetby();
        }
      }, 500);
    }

    const sub = globalStore.betbyBackSubject.subscribe(code => {
      if (code === 'back') {
        handleBack();
      }
    });

    return () => {
      if (debounceTimer.current != null) {
        clearTimeout(debounceTimer.current);
        debounceTimer.current = undefined;
      }
      sub.unsubscribe();

      setTimeout(() => {
        if (
          (window as any).platformBetby &&
          !window.location.pathname.includes('BetBy')
        ) {
          (window as any).platformBetby.kill();
          (window as any).platformBetby = undefined;
        }
      }, 100);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useFocusEffect(
    useCallback(() => {
      toggle(true);
      return () => {
        setTimeout(() => {
          if (!window.location.pathname.includes('BetBy')) {
            toggle(false);
          }
        }, 100);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  useEffect(() => {
    globalStore.updateAmount.next({});
    let intv: NodeJS.Timeout | null = setInterval(() => {
      globalStore.updateAmount.next({});
    }, 20000);
    return () => {
      if (intv != null) {
        clearInterval(intv);
        intv = null;
      }
    };
  }, []);

  const handleBack = useCallback(() => {
    localStorage.removeItem('betbyRouteStatus');
    goBack();
  }, []);
  return <></>;
};

export default BetByContainer;
